<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />

    <div class="date-setting-button-div" v-if="checkPersonaForDateSetting">
      <b-button v-b-modal.governanceCompletionDate pill class="asmt-bt date-setting" size="lg" @click="dateSetting()">
        <img alt="more" class="calender-icon" src="../../../assets/calendar-outline.svg" />
        <span class="primary-color setting-text">Date Setting</span>
      </b-button>
    </div>
    <div class="main-con no-fl-con" v-if="!validationFlowInProgress">
      <img alt="NoGovStarted" src="../../../assets/NoGovStarted.svg" class="no-fl-img" />
      <div class="no-fl-hd">The Governance cycle is yet to be started.</div>
      <div class="no-fl-sub"></div>
      <div></div>
    </div>
    <div v-else>
      <div class="main-con">
        <div class="detail-con">
          <div class="dd-con" v-if="submittedReqApiStatus">
            <div class="plant-dd d-flex width-50">
              <div class="dd-selection" v-if="loggedInRole != roles.SYSTEMLEVELADMINNAME">
                <label for="pillar" class="form-label select-lable lable-text lable-dp">
                  {{ personaSelctionLable }}
                </label>
                <select class="form-control select-text select-text-pd cust-st-icon disable_fd" id="pillar" disabled
                  v-if="loggedInRole != roles.USERNAME">
                  <option v-for="item in personaSelctionValue" v-bind:key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
                <input v-else type="text" class="form-control select-text select-text-pd disable_fd" disabled
                  :value="personaSelctionValue[0].name" />
              </div>
              <div class="button-r" v-if="!validationReqStatus && !checkPersonaForDateSetting">
                <b-button pill class="cahge-req-btn"
                  :disabled="!submittedReqStatus.submitRequestStatus && !checkPersonaForDateSetting"
                  @click="submitRequest()">
                  Submit Change Request
                </b-button>
              </div>
            </div>
            <div class="cml-by width-50" v-if="loggedInRole != roles.SYSTEMLEVELADMINNAME">
              <div class="cmp-by-txt">
                Open from {{ submittedReqStatus.startDate | dateFormater }} to
                {{ submittedReqStatus.endDate | dateFormater }}
              </div>
              <div><img alt="...." src="../../../assets/complete-by.svg" /></div>
            </div>
            <div class="cml-by width-50" v-if="loggedInRole == roles.SYSTEMLEVELADMINNAME">
              <div class="cmp-by-txt">
                Open from
                {{ submittedReqStatus.publishStartDate | dateFormater }}
              </div>
              <div><img alt="...." src="../../../assets/complete-by.svg" /></div>
            </div>
          </div>
          <div v-if="loggedInRole == roles.USERNAME">
            <div class="main-header my-req">My Requests</div>
            <div>
              <b-table sticky-header id="governanve-table" class="action-table gv-table" responsive
                :items="historyprincipalData" :fields="myReqFields" :per-page="perPage" :current-page="currentPage">
                <template #cell(actions)="row">
                  <b-button class="asmt-bt" pill variant="outline-primary" @click="viewConsolidated(row.item.requestId)">
                    View </b-button>
                  <b-button class="asmt-bt edit-mr" pill variant="outline-primary"
                    @click="viewConsolidated(row.item.requestId, 1, row.item.status)"
                    :disabled="!submittedReqStatus.submitRequestStatus || row.item.status == 'Reviewed'">
                    Edit
                  </b-button>
                </template>
                <template #cell(submittedDate)="row">
                  {{ row.item.submittedDate | dateFormater }}
                </template>
                <template #cell(practice)="row"> {{ row.item.principleNumber }}.{{ row.item.levelId }} </template>
                <template #cell(proposedPractice)="row">
                  <div>
                    {{ practiceDescription(row.item.proposedPractice) }}
                  </div>
                </template>
              </b-table>
              <div class="text-cen" v-if="historyprincipalData && historyprincipalData.length == 0">
                <img alt="no data" src="../../../assets/No_Data.svg" />
                <div class="ed-date">No Records Found</div>
              </div>
              <div v-if="historyprincipalData && historyprincipalData.length > 5" class="row pagination-con">
                <div class="col-lg-3 col-md-12 col-sm-12 record-info">
                  {{ showingData(historyprincipalData.length) }}
                </div>
                <div class="col-lg-7 col-md-12 col-sm-12 pagination-con">
                  <b-pagination class="pagination-align record-info" v-model="currentPage"
                    :total-rows="historyprincipalData.length" :per-page="perPage" aria-controls="my-table"
                    prev-text="Back" next-text="Next" first-text="First" last-text="Last"></b-pagination>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12 record-info text-rt">
                  <div class="per-page-cont record-info">
                    <select v-model="perPage" class="per-page" aria-label="Default select example">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option v-if="historyprincipalData.length > 10 && historyprincipalData.length < 50"
                        :value="historyprincipalData.length">
                        {{ historyprincipalData.length }}
                      </option>
                    </select>
                    Per page
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="main-header my-req">{{ incomingLable }}</div>
            <div class="pillar-con">
              <div class="pillar-info" :class="{
                  'active-pillar': pillarSelectedInGov == pillar.pillarId,
                  'inactive-pillar ': pillar.active == 'N',
                  'first-col': index == 0,
                  'last-col': index == pillarData.length - 1
                }" v-for="(pillar, index) in pillarData" v-bind:key="pillar.pillarId"
                @click="pillarChanged(pillar, pillar.active)">
                <span class="pillar-code">{{ pillar.pillarCode }}</span><span class="pillar-count"
                  v-if="pillar.count > 0">{{ pillar.count | doubleDigit }}</span><span class="pillar-count"
                  v-if="pillar.requestCount > 0">{{ pillar.requestCount | doubleDigit }}</span>
              </div>
            </div>
            <div class="main-header pillar-name-con">
              {{ pillarNameSelectedInGov }}
            </div>
            <div class="practice-main-con" v-for="(principle, princpleIndex) in principalData"
              v-bind:key="principle.principleId">
              <div class="practice-con" :class="{
                  'practice-con-inactv': principalSelectedGov != principle.principleId || validationReqStatus
                }">
                <div class="flex-con" @click="principalChanged(principle.principleId)">
                  <div>
                    <img alt="more" v-if="principalSelectedGov == principle.principleId"
                      src="../../../assets/gov-up.svg" />
                    <img alt="more" v-else src="../../../assets/gov-down.svg" />
                  </div>
                  <div class="pracities-no ml-2">
                    <div class="prac-number">
                      {{ principle.principleNumber }}
                    </div>
                  </div>
                  <div class="main-header ml-2">
                    {{ principle.principleName }}
                    <span class="pillar-count" v-if="principle.totalCount && principle.totalCount > 0">{{
                      principle.totalCount | doubleDigit }}</span>
                    <span class="pillar-count"
                      v-if="principle.validationRequest && principle.validationRequest.length > 0">
                      {{ principle.validationRequest.length | doubleDigit }}</span>
                  </div>
                </div>
                <hr class="level-hr" v-if="principalSelectedGov == principle.principleId && !validationReqStatus" />
                <div class="level-con flex-con"
                  v-if="principalSelectedGov == principle.principleId && !validationReqStatus">
                  <div class="sing-level flex-con" v-for="(level, levelIndex) in principle.levelDetails"
                    v-bind:key="level.levelId" @click="levelChanged(level.levelId, princpleIndex, levelIndex)">
                    <span class="level-name" :class="{
                        'active-level': levelSelectedGov == level.levelId
                      }">{{ level.levelDescription }}</span><span class="pillar-count"
                      v-if="level.levelWiseRequestDetails.length > 0">{{ level.levelWiseRequestDetails.length |
                        doubleDigit }}</span>
                    <hr class="opacity-0" :class="{
                      'active-hr': levelSelectedGov == level.levelId
                    }" />
                  </div>
                </div>
              </div>
              <div class="perform-act-con"
                v-if="principalSelectedGov == principle.principleId && selectedLevelData.length > 0 && !validationReqStatus">
                <b-button pill class="asmt-bt perform-btn" size="lg"
                  @click="callPerformAction(principle, levelSelectedGov)"
                  :disabled="checkPersonaForPerAct || !submittedReqStatus.performActionButton">
                  Perform Action
                </b-button>
              </div>
              <div class="mt-3" v-if="principalSelectedGov == principle.principleId && !validationReqStatus">
                <b-table sticky-header id="pillar-wise-table" class="action-table gv-table" responsive
                  :items="selectedLevelData" :fields="inReqFields" :per-page="perPage" :current-page="currentPage">
                  <template #cell(actions)="row">
                    <b-button class="asmt-bt" pill variant="outline-primary"
                      @click="viewPractice(row.item.requestId, principle.principleNumber + '. ' + principle.principleName, pillarNameSelectedInGov, principle.principleId)">
                      View
                    </b-button>
                  </template>
                  <template #cell(proposedPractice)="row">
                    <div>
                      {{ practiceDescription(row.item.proposedPractice) }}
                    </div>
                  </template>
                  <template #cell(submittedDate)="row">
                    {{ row.item.submittedDate | dateFormater }}
                  </template>
                  <template #cell(practice)="row"> {{ row.item.principleNumber }}.{{ row.item.levelId }} </template>
                </b-table>
              </div>
              <div
                v-if="selectedLevelData.length == 0 && principalSelectedGov == principle.principleId && !validationReqStatus"
                class="no-data">
                <div><img alt="no data" src="../../../assets/No_Data.svg" /></div>
                <span class="ed-date">No Records Found</span>
              </div>
              <div class="mt-3" v-if="principalSelectedGov == principle.principleId && validationReqStatus">
                <b-table sticky-header id="validation-reg-table" class="action-table gv-table" responsive
                  :items="validationReqData" :fields="validationReqFields" :per-page="perPage"
                  :current-page="currentPage">
                  <template #cell(actions)="row">
                    <b-button v-if="loggedInRole == roles.PLANTPOLEADERNAME" class="asmt-bt validation-btn" pill
                      variant="outline-primary" @click="requestValidation(
                          row.item.reqId,
                          principle.principleNumber + '. ' + principle.principleName,
                          pillarNameSelectedInGov,
                          row.item.practiceId,
                          1,
                          row.item.levelId,
                          row.item.pillarId
                        )
                        ">
                      Validate
                    </b-button>
                    <b-button
                      v-if="loggedInRole == roles.GLOBALPILLARLEADERNAME || loggedInRole == roles.SYSTEMLEVELADMINNAME"
                      class="asmt-bt" :class="{
                          'opacity-01': loggedInRole == roles.SYSTEMLEVELADMINNAME
                        }" pill variant="outline-primary" @click="reviewForGlobalValidation(
      row.item.reqId,
      principle.principleNumber + '. ' + principle.principleName,
      pillarNameSelectedInGov,
      principle.principleId,
      1,
      row.item.levelId,
      row.item.pillarId
    )
    ">
                      Review
                    </b-button>
                  </template>
                  <template #cell(feedbackDate)="row">
                    {{ row.item.feedbackDate | dateFormater }}
                  </template>
                  <template #cell(assignedDate)="row">
                    {{ row.item.assignedDate | dateFormater }}
                  </template>
                  <template #cell(plants)="row">
                    {{ plantsData(row.item.plants) }}
                  </template>
                  <template #cell(practice)="row"> {{ row.item.principleNumber }}.{{ row.item.levelId }} </template>
                </b-table>
              </div>
              <div
                v-if="validationReqData.length == 0 && principalSelectedGov == principle.principleId && validationReqStatus"
                class="no-data">
                <div><img alt="no data" src="../../../assets/No_Data.svg" /></div>
                <span class="ed-date">No Records Found</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-con" v-if="loggedInRole != roles.USERNAME">
        <div class="detail-con">
          <div class="main-header">{{ reqHistoryLable }}</div>
          <div class="pillar-con">
            <div class="pillar-info" :class="{
                'active-pillar': pillarSelectedGovHis == pillar.pillarId,
                'inactive-pillar ': pillar.active == 'N',
                'first-col': index == 0,
                'last-col': index == pillarData.length - 1
              }" v-for="(pillar, index) in pillarDataHistr" v-bind:key="pillar.pillarId"
              @click="pillarChangedReq(pillar, pillar.active)">
              <span class="pillar-code">{{ pillar.pillarCode }}</span>
              <span class="pillar-count" v-if="pillar.count > 0">{{ pillar.count | doubleDigit }}</span>
              <span class="pillar-count" v-if="pillar.requestCount > 0">{{ pillar.requestCount | doubleDigit }}</span>
            </div>
          </div>
          <div>
            <div class="mt-2" v-if="!validationReqStatus">
              <b-table sticky-header id="pillar-wise-table" class="action-table gv-table" responsive
                :items="historyprincipalData" :fields="reqHisFields" :per-page="perPage" :current-page="currentPage">
                <template #cell(actions)="row">
                  <b-button class="asmt-bt" pill variant="outline-primary" @click="viewConsolidated(row.item.requestId)">
                    View </b-button>
                  <b-button class="asmt-bt edit-btn" pill variant="outline-primary"
                    @click="editRequest(row.item.requestId)" v-if="loggedInRole == roles.GLOBALPILLARLEADERNAME"
                    :disabled="row.item.status == 'Reviewed' || !continueToAssignPlantValidationStatus"
                    :class="{ 'opacity-01': row.item.status == 'Reviewed' }">
                    Edit
                  </b-button>
                </template>
                <template #cell(practice)="row">
                  <div>{{ row.item.principleNumber }}.{{ row.item.levelId }}</div>
                </template>
                <template #cell(processedDate)="row">
                  {{ row.item.processedDate | dateFormater }}
                </template>
                <template #cell(submittedBy)="row">
                  {{ submittedByText(row.item.submittedBy) }}
                </template>
              </b-table>
              <div v-if="historyprincipalData.length == 0" class="no-data">
                <div><img alt="no data" src="../../../assets/No_Data.svg" /></div>
                <span class="ed-date">No Records Found</span>
              </div>
              <div v-if="historyprincipalData && historyprincipalData.length > 0" class="row pagination-con">
                <div class="col-lg-2 col-md-12 col-sm-12 record-info">
                  {{ showingData(historyprincipalData.length) }}
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 pagination-con">
                  <b-pagination class="pagination-align record-info" v-model="currentPage"
                    :total-rows="historyprincipalData.length" :per-page="perPage" aria-controls="my-table"
                    prev-text="Back" next-text="Next" first-text="First" last-text="Last"></b-pagination>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12 record-info text-rt">
                  <div class="per-page-cont record-info">
                    <select v-model="perPage" class="per-page" aria-label="Default select example">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option v-if="historyprincipalData.length > 50" value="50">50</option>
                      <option v-if="historyprincipalData.length > 10 && historyprincipalData.length < 50">
                        {{ historyprincipalData.length }}
                      </option>
                    </select>
                    Per page
                  </div>
                </div>
              </div>
              <div class="button-r gp-btn"
                v-if="loggedInRole == roles.GLOBALPILLARLEADERNAME && historyprincipalData.length > 0">
                <b-button v-if="!validationReqStatus" pill
                  :disabled="continueToAssignPlantValidationStatus || historyprincipalData.length == 0" class="asmt-bt"
                  :class="{
                      'opacity-01': continueToAssignPlantValidationStatus || historyprincipalData.length == 0
                    }" @click="$router.push('/assign-plant-validation')">
                  Continue to Assign Plant Validation
                </b-button>
                <b-button v-if="!validationReqStatus" pill class="cahge-req-btn"
                  :disabled="!continueToAssignPlantValidationStatus || historyprincipalData.length == 0"
                  :class="{ 'opacity-01': !continueToAssignPlantValidationStatus || historyprincipalData.length == 0 }"
                  @click="() => { this.$router.push('/review-process'); }">
                  Proceed to Governance Review Process
                </b-button>
              </div>
            </div>
            <div class="mt-2" v-if="validationReqStatus">
              <b-table sticky-header id="pillar-wise-table" class="action-table gv-table" responsive
                :items="selectedValidationReqHisData" :fields="validationReqHisFields" :per-page="perPage"
                :current-page="currentPage">
                <template #cell(actions)="row">
                  <b-button v-if="loggedInRole == roles.PLANTPOLEADERNAME" class="asmt-bt" pill variant="outline-primary"
                    @click="requestValidation(
                        row.item.reqId,
                        row.item.principleNumber + '. ' + row.item.principleName,
                        pillarNameSelected,
                        row.item.practiceId,
                        2,
                        row.item.levelId,
                        row.item.pillarId
                      )
                      ">
                    View
                  </b-button>
                  <b-button v-else class="asmt-bt" pill variant="outline-primary" @click="reviewForGlobalValidation(
                      row.item.reqId,
                      row.item.principleNumber + '. ' + row.item.principleName,
                      pillarNameSelectedInGov,
                      row.item.principleId,
                      0,
                      row.item.levelId,
                      row.item.pillarId
                    )
                    ">
                    View
                  </b-button>
                </template>
                <template #cell(practice)="row">
                  <div>{{ row.item.principleNumber }}.{{ row.item.levelId }}</div>
                </template>
                <template #cell(plantNames)="row">
                  {{ plantsData(row.item.plantNames) }}
                </template>
                <template #cell(reviewedDate)="row">
                  {{ row.item.reviewedDate | dateFormater }}
                </template>
              </b-table>
              <div v-if="selectedValidationReqHisData.length == 0" class="no-data">
                <div><img alt="no data" src="../../../assets/No_Data.svg" /></div>
                <span class="ed-date">No Records Found</span>
              </div>
              <div v-if="selectedValidationReqHisData && selectedValidationReqHisData.length > 0"
                class="row pagination-con">
                <div class="col-lg-2 col-md-12 col-sm-12 record-info">
                  {{ showingData(selectedValidationReqHisData.length) }}
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 pagination-con">
                  <b-pagination class="pagination-align record-info" v-model="currentPage"
                    :total-rows="selectedValidationReqHisData.length" :per-page="perPage" aria-controls="my-table"
                    prev-text="Back" next-text="Next" first-text="First" last-text="Last"></b-pagination>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12 record-info text-rt">
                  <div class="per-page-cont record-info">
                    <select v-model="perPage" class="per-page" aria-label="Default select example">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option v-if="selectedValidationReqHisData.length > 50" value="50">50</option>
                      <option v-if="selectedValidationReqHisData.length > 10 && selectedValidationReqHisData.length < 50">
                        {{ selectedValidationReqHisData.length }}
                      </option>
                    </select>
                    Per page
                  </div>
                </div>
              </div>
              <div class="button-r gp-btn" v-if="this.loggedInRole == this.roles.SYSTEMLEVELADMINNAME">
                <b-button v-if="submittedReqStatus.publishStatus && selectedValidationReqHisData.length > 0" pill
                  class="cahge-req-btn" v-b-modal.publishPopup>
                  Publish Assessment
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DateSettingPopup v-if="checkPersonaForDateSetting" text="Set Timeline for Governance Process"
      v-bind:getStartDate="getStartDate" v-bind:getEndDate="getEndDate" v-bind:getActive="getActive"
      v-bind:updatedByUser="updatedByUserPopup" v-bind:updatedByUserDate="updatedByUserDatePopup"
      v-bind:updatedByUserTime="updatedByUserTimePopup" v-bind:govIds="govIds" :isNull="isNull"
      @setLoader="setLoader($event)" />
    <template>
      <b-modal id="publishPopup" ref="publishPopup">
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <p class="pop-up-title">Publish Assessment</p>
          <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
        </template>

        <template #default="{}">
          <div>
            <div class="popup-text bottom-1rem">
              The Assessment has been updated and is ready to publish. Do you wish to
              proceed?
            </div>
          </div>
        </template>

        <template #modal-footer="{ close }">
          <div class="rw-act-con">
            <b-button @click="close()" pill variant="outline-secondary"
              class="ed-st-btn rounded-pill btn discard-btn btn-secondary"> Discard </b-button>
            <b-button @click="publishAssesment()" pill variant="outline-secondary"
              class="ed-st-btn rounded-pill btn publish-btn btn-secondary"> Publish Assessment </b-button>
          </div>
        </template>
      </b-modal>
    </template>
  </div>
</template>
<script>
import ApiCalls from "@/common/api/apiCalls";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import Api from "../../../common/constants/api";
import DateSettingPopup from "./DateSettingPopup.vue";
import Role from "../../../common/constants/roles";
import moment from "moment";
import { mapGetters } from "vuex";
import helper from "@/common/helper";
export default {
  name: "Governance",
  data() {
    return {
      showLoader: false,
      plantList: [],
      plantId: 3,
      myReqFields: [
        {
          key: "pillarCode",
          sortable: false,
          label: "Pillar"
        },
        {
          key: "practice",
          label: "Practice",
          sortable: false
        },
        {
          key: "proposedPractice",
          label: "Proposed Practice",
          sortable: false
        },
        {
          key: "changeType",
          label: "Change Type",
          sortable: false
        },
        {
          key: "submittedDate",
          label: "Submitted Date",
          sortable: false
        },
        {
          key: "status",
          label: "Status",
          sortable: false
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      pillarData: [],
      pillarN: "",
      pillarDataHistr: [],
      perPage: 10,
      currentPage: 1,
      actionItemsSortBy: "submittedDate",
      actionItemsSortDesc: true,
      loggedInRole: "",
      roles: Role,
      principleNum: "",
      inReqFields: [
        {
          key: "practice",
          label: "Practice",
          sortable: false
        },
        {
          key: "proposedPractice",
          label: "Proposed Practice",
          sortable: false
        },
        {
          key: "changeType",
          label: "Change Type",
          sortable: false
        },
        {
          key: "submittedBy",
          label: "Submitted By",
          sortable: false
        },
        {
          key: "plant",
          label: "Plant",
          sortable: false
        },
        {
          key: "region",
          label: "Region",
          sortable: false
        },
        {
          key: "submittedDate",
          label: "Submitted Date",
          sortable: false
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      reqHisFields: [
        {
          key: "pillarCode",
          sortable: false,
          label: "Pillar"
        },
        {
          key: "practice",
          label: "Practice",
          sortable: false
        },
        {
          key: "decisionType",
          label: "Decision Type",
          sortable: false
        },
        {
          key: "submittedBy",
          label: "Submitted By",
          sortable: false
        },
        {
          key: "plant",
          label: "Plant",
          sortable: false
        },
        {
          key: "region",
          label: "Region",
          sortable: false
        },
        {
          key: "processedBy",
          label: "Processed By",
          sortable: false
        },
        {
          key: "processedDate",
          label: "Processed Date",
          sortable: false
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      updatedByUserPopup: {},
      updatedByUserDatePopup: {},
      targetLevelCompletionDate: "",
      updatedByUserTimePopup: {},
      govIds: {},
      getEndDate: {},
      getStartDate: {},
      getActive: {},
      openFrom: "",
      openTo: "",
      currentPersona: "globalPOFB",
      submittedReqStatus: {},
      personaSelctionLable: "",
      personaSelctionValue: [],
      submittedReqApiStatus: false,
      principalData: [],
      historyprincipalData: [],
      selectedPrincipalId: 0,
      selectedLevelData: [],
      historyData: [],
      previousRequestId: "",
      requestId: "",
      pillarId: "",
      pillarName: "",
      principleName: "",
      principleId: "",
      pillarNameSelected: "",
      validationReqStatus: false,
      incomingLable: "",
      reqHistoryLable: "",
      pillarNumber: "",
      validationReqFields: [
        {
          key: "practice",
          label: "Practice",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "levelId",
          label: "Level",
          sortable: false,
          tdClass: "validation-lv"
        },
        {
          key: "assignedBy",
          label: "Assigned By",
          sortable: false,
          tdClass: "validation-ab"
        },
        {
          key: "assignedDate",
          label: "Assigned Date",
          sortable: false
        },
        {
          key: "plants",
          label: "Plants",
          sortable: false,
          tdClass: "validation-plant"
        },
        {
          key: "feedbackDate",
          label: "Feedback Date",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      validationReqHisFields: [
        {
          key: "pillarCode",
          label: "Pillar",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "practice",
          label: "Practice",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "reviewDecision",
          label: "Review Decision",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "assignedBy",
          label: "Assigned By",
          sortable: false,
          tdClass: "validation-level"
        },
        // {
        //   key: "assignedTo",
        //   label: "Assigned To",
        //   sortable: false,
        //   tdClass: "validation-level",
        // },
        {
          key: "plantNames",
          label: "Plants",
          sortable: false,
          tdClass: "validation-plant"
        },
        {
          key: "reviewedBy",
          label: "Reviewed By",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "reviewedDate",
          label: "Reviewed Date",
          sortable: false,
          tdClass: "validation-level"
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      validationReqData: [],
      validationReqHisData: [],
      selectedValidationReqHisData: [],
      continueToAssignPlantValidationStatus: false,
      isNull: false,
      validationFlowInProgress: true
    };
  },
  components: {
    Breadcrumb,
    Loader,
    DateSettingPopup
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4
    });
  },
  computed: {
    ...mapGetters(["pillarSelectedInGov"]),
    ...mapGetters(["principalSelectedGov"]),
    ...mapGetters(["pillarSelectedGovHis"]),
    ...mapGetters(["levelSelectedGov"]),
    ...mapGetters(["pillarNameSelectedInGov"]),
    checkPersonaForDateSetting() {
      return (this.$oidc.userProfile.roles.includes(this.roles.SYSTEMLEVELADMIN))
    },
    checkPersonaForPerAct() {
      return (this.$oidc.userProfile.roles.includes(this.roles.USER) && this.$oidc.userProfile.roles.length == 1)
    }
  },
  created() {
    if (
      !(
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.USER)
      )
    ) {
      this.$router.push("/");
      return;
    }
    this.getDateSettingData();
    this.getSubmittedReqStatus();
    if (this.$oidc.userProfile.roles.includes(this.roles.SYSTEMLEVELADMIN)) this.loggedInRole = this.roles.SYSTEMLEVELADMINNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) this.loggedInRole = this.roles.GLOBALPILLARLEADERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER)) this.loggedInRole = this.roles.REGIONALPOLEADERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)) this.loggedInRole = this.roles.REGIONALPILLARLEADERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER)) this.loggedInRole = this.roles.PLANTMANAGERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER)) this.loggedInRole = this.roles.PLANTPOLEADERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER)) this.loggedInRole = this.roles.PLANTPILLARLEADERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER)) this.loggedInRole = this.roles.PLANTSUBPILLARLEADERNAME;
    else if (this.$oidc.userProfile.roles.includes(this.roles.USER)) this.loggedInRole = this.roles.USERNAME;
    this.$store.dispatch("breadcrumb", {
      title: "Governance - " + this.loggedInRole,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Governance", primary: false }
      ]
    });
  },
  methods: {
    submitRequest() {
      if (!this.submittedReqStatus.submitRequestStatus) return;
      this.$router.push("/submitRequest/" + this.submittedReqStatus.id);
    },
    getPlantList() {
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        this.plantList = data.data;
      });
    },
    publishIt() { },
    getSubmittedReqStatus() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.GETSUBMITREQSTATUS, "get").then((data) => {
        if (data.success) {
          if (data.data) {
            this.validationFlowInProgress = true;
            this.submittedReqApiStatus = true;
            helper.setGovIdForFinalAssessment(data.data.publishGovId);
            this.submittedReqStatus = data.data;
            this.personaSelctionLable = this.submittedReqStatus.objectType == "global" ? "Pillar" : this.submittedReqStatus.objectType;
            this.personaSelctionValue = [
              {
                id: this.submittedReqStatus.id ? this.submittedReqStatus.id : 0,
                name: this.submittedReqStatus.name
              }
            ];
            if (this.loggedInRole == this.roles.USERNAME) {
              this.getPillarWiseHisryData();
            } else {
              if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
                this.validationReqStatus = this.submittedReqStatus.plantPoValidationFlowStatus ? true : false;
              } else if (this.loggedInRole == this.roles.GLOBALPILLARLEADERNAME) {
                this.validationReqStatus = this.submittedReqStatus.globalPillarLeaderValidationFlowStatus === true ? true : false;
              } else if (this.loggedInRole === this.roles.SYSTEMLEVELADMINNAME) {
                this.validationReqStatus = this.submittedReqStatus.publishStatus === true ? true : false;
              }
              if (this.validationReqStatus) {
                if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
                  this.submittedReqStatus.startDate = this.submittedReqStatus.plantPoValidationStartDate;
                  this.submittedReqStatus.endDate = this.submittedReqStatus.plantPoValidationEndDate;
                } else {
                  this.submittedReqStatus.startDate = this.submittedReqStatus.globalValidationStartDate;
                  this.submittedReqStatus.endDate = this.submittedReqStatus.globalValidationEndDate;
                }
              }
              if (
                (this.loggedInRole == this.roles.PLANTPOLEADERNAME ||
                  this.loggedInRole == this.roles.GLOBALPILLARLEADERNAME ||
                  this.loggedInRole == this.roles.SYSTEMLEVELADMINNAME) &&
                this.validationReqStatus === true
              ) {
                this.showLoader = false;
                this.incomingLable = "Validation Requests";
                this.reqHistoryLable = this.loggedInRole == this.roles.PLANTPOLEADERNAME ? "Validation History" : "Final Assessment Changes";
                // Remove fields according to role
                if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
                  let index = this.validationReqFields.findIndex((el) => el.key == "plants");
                  if (index > -1) this.validationReqFields.splice(index, 1);
                  let indexReg = this.validationReqFields.findIndex((el) => el.key == "feedbackDate");
                  if (indexReg > -1) this.validationReqFields.splice(indexReg, 1);
                  let decisionIdx = this.validationReqHisFields.findIndex((el) => el.key == "plantNames");
                  if (decisionIdx > -1) this.validationReqHisFields.splice(decisionIdx, 1);
                  let reviewedBy = this.validationReqHisFields.findIndex((el) => el.key == "reviewedBy");
                  if (reviewedBy > -1) this.validationReqHisFields.splice(reviewedBy, 1);
                } else {
                  let index = this.validationReqFields.findIndex((el) => el.key == "assignedBy");
                  if (index > -1) this.validationReqFields.splice(index, 1);
                  let indexReg = this.validationReqFields.findIndex((el) => el.key == "assignedDate");
                  if (indexReg > -1) this.validationReqFields.splice(indexReg, 1);
                  let decisionIdx = this.validationReqHisFields.findIndex((el) => el.key == "reviewDecision");
                  if (decisionIdx > -1) this.validationReqHisFields.splice(decisionIdx, 1);
                  let asssnbyIndex = this.validationReqHisFields.findIndex((el) => el.key == "assignedBy");
                  if (asssnbyIndex > -1) this.validationReqHisFields.splice(asssnbyIndex, 1);
                }
                this.getPillarWiseCountForValidation(data.data.userType);
                this.getPillarWiseCountHisryForValidation();
                return;
              }
              this.incomingLable = "Incoming Requests";
              this.reqHistoryLable = this.loggedInRole == this.roles.GLOBALPILLARLEADERNAME ? "Proposed Assessment Changes" : "Request History";
              this.getPillarWiseCount(data.data.userType);
              this.getPillarWiseCountHisry();
              if (this.submittedReqStatus.objectType == "plant") {
                let index = this.inReqFields.findIndex((el) => el.key == "plant");
                this.inReqFields.splice(index, 1);
                let indexReg = this.inReqFields.findIndex((el) => el.key == "region");
                this.inReqFields.splice(indexReg, 1);
              } else if (this.submittedReqStatus.objectType == "region") {
                let index = this.inReqFields.findIndex((el) => el.key == "region");
                this.inReqFields.splice(index, 1);
              } else if (this.submittedReqStatus.objectType == "global") {
                let index = this.inReqFields.findIndex((el) => el.key == "plant");
                if (index > -1) this.inReqFields.splice(index, 1);
              }
            }
          } else {
            this.validationFlowInProgress = false;
            this.submittedReqApiStatus = false;
            this.showLoader = false;
          }
        }
      });
    },
    getPillarWiseCount(userType) {
      let queryParm = "?id=" + (this.personaSelctionValue[0].id ? this.personaSelctionValue[0].id : 0)
      queryParm = queryParm + "&UserType=" + userType
      ApiCalls.httpCallDetails(Api.GETPILLARWISEREQCOUNT + queryParm, "get").then(
        (data) => {
          if (data.success) {
            this.pillarData = data.data;
            if (this.pillarSelectedInGov == 0) {
              this.$store.dispatch("pillarSelectedInGov", this.pillarData[0].pillarId);
              this.$store.dispatch("pillarNameSelectedInGov", this.pillarData[0].pillarName);
              this.pillarN = this.pillarData[0].pillarName;
            }
            this.getPrincipleData(this.submittedReqStatus.objectType);
          } else {
            this.showLoader = false;
          }
        }
      );
    },
    getPillarWiseCountForValidation(userType) {
      let url;
      if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
        url = Api.PLANTPOVALIDATIONCOUNT + this.submittedReqStatus.id;
      } else url = Api.GETVALIDATIONREQCNTBYGLOBAL;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success) {
          this.pillarData = data.data;
          if (this.pillarSelectedInGov == 0) {
            this.$store.dispatch("pillarSelectedInGov", this.pillarData[0].pillarId);
            this.$store.dispatch("pillarNameSelectedInGov", this.pillarData[0].pillarName);
            this.pillarN = this.pillarData[0].pillarName;
          }
          this.getPrincipleDataForValidation(this.submittedReqStatus.objectType);
        } else {
          this.showLoader = false;
        }
      });
    },
    getPrincipleData(objectType) {
      let url = Api.GETREQUESTDETAILSBYPILLAR + "/" + this.pillarSelectedInGov;
      if (objectType == "plant") url += "?PlantId=" + this.submittedReqStatus.id;
      else if (objectType == "region") url += "?RegionalId=" + this.submittedReqStatus.id;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.showLoader = false;
        if (data.success) {
          this.principalData = data.data;
          this.$store.dispatch("principalSelectedGov", this.principalData[0].principleId);
          if (!this.levelSelectedGov) {
            this.$store.dispatch("levelSelectedGov", this.principalData[0].levelDetails[0].levelId);
          } else {
            let principleIndex = this.principalData.findIndex((el) => el.principleId == this.pillarSelectedInGov);
            let selectedId = this.levelSelectedGov;
            let leveIndex = this.principalData[principleIndex]["levelDetails"].findIndex((el) => el.levelId == selectedId);

            this.selectedLevelData = this.principalData[principleIndex].levelDetails[leveIndex].levelWiseRequestDetails;
          }
        }
      });
    },
    getPrincipleDataForValidation() {
      let url;
      if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
        url = Api.PLANTPOVALIDATIONDATA + this.pillarSelectedInGov;
      } else url = Api.GLOVALPILLARVALIDATIONLIST + this.pillarSelectedInGov;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.showLoader = false;
        if (data.success) {
          this.principalData = data.data;
          this.validationReqData = data.data[0].validationRequest;

          this.levelId = this.validationReqData.length != 0 ? this.validationReqData[0].levelId : 0;
          this.$store.dispatch("principalSelectedGov", this.validationReqData.length != 0 ? this.validationReqData[0].principleId : 0);
        }
      });
    },
    getPillarWiseCountHisry() {
      ApiCalls.httpCallDetails(Api.GETPILLARWISEREQCOUNTHISTORY, "get").then((data) => {
        if (data.success) {
          this.pillarDataHistr = data.data;
          if (this.pillarSelectedGovHis == 0) this.$store.dispatch("pillarSelectedGovHis", this.pillarDataHistr[0].pillarId);
          this.pillarId = this.pillarDataHistr[0].pillarId;
          this.pillarName = this.pillarDataHistr[0].pillarName;
          this.pillarNameSelected = this.pillarDataHistr[0].pillarName;
          this.getPillarWiseHisryData();
          this.getContinueToAssignPlantValidationStatus();
        }
      });
    },
    getContinueToAssignPlantValidationStatus() {
      ApiCalls.httpCallDetails(Api.GETREVIEWPRACTICESTSTATUSDATA, "get").then((data) => {
        if (data.success) {
          for (const element of data.data) {
            if (!element.createdValidationVersion) {
              this.continueToAssignPlantValidationStatus = true;
            }
          }
        }
      });
    },
    getPillarWiseCountHisryForValidation() {
      let url;
      if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
        url = Api.PLANTPOVALIDATIONHISTORYPILLARCOUNT + this.submittedReqStatus.id;
      } else url = Api.GETVALIDATIONHSTREQCNTBYGLOBAL;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success) {
          this.pillarDataHistr = data.data;
          if (this.pillarSelectedGovHis == 0) this.$store.dispatch("pillarSelectedGovHis", this.pillarDataHistr[0].pillarId);
          this.pillarId = this.pillarDataHistr[0].pillarId;
          this.pillarName = this.pillarDataHistr[0].pillarName;
          this.pillarNameSelected = this.pillarDataHistr[0].pillarName;
          this.getPillarWiseHisryDataForValidation();
        }
      });
    },
    getPillarWiseHisryData() {
      let url;
      if (this.loggedInRole == this.roles.USERNAME) {
        url = Api.GETREQUESTHISDETAILSBYPILLAR + "?PlantOrRegionId=" + this.submittedReqStatus.id;
      } else {
        url = Api.GETREQUESTHISDETAILSBYPILLAR + "/" + this.pillarSelectedGovHis;
        if (this.submittedReqStatus.objectType == "plant") {
          let index = this.reqHisFields.findIndex((el) => el.key == "plant");
          if (index > -1) this.reqHisFields.splice(index, 1);
          let indexReg = this.reqHisFields.findIndex((el) => el.key == "region");
          if (index > -1) this.reqHisFields.splice(indexReg, 1);
        } else if (this.submittedReqStatus.objectType == "region") {
          let index = this.reqHisFields.findIndex((el) => el.key == "region");
          if (index > -1) this.reqHisFields.splice(index, 1);
        } else if (this.submittedReqStatus.objectType == "global") {
          let index = this.reqHisFields.findIndex((el) => el.key == "plant");
          if (index > -1) this.reqHisFields.splice(index, 1);
        }
        if (this.submittedReqStatus.objectType == "plant" || this.submittedReqStatus.objectType == "region") url += "?PlantOrRegionId=" + this.submittedReqStatus.id;
      }
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.showLoader = false;
        if (data.success) {
          data.data.sort((a, b) => {
            if (new Date(a.processedDate) < new Date(b.processedDate)) return 1;
            else if (new Date(a.processedDate) > new Date(b.processedDate)) return -1;
            return 0;
          });
          this.historyprincipalData = data.data;
        }
      });
    },
    getPillarWiseHisryDataForValidation() {
      let url;
      if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) url = Api.PLANTPOVALIDATIONHISTORY;
      else {
        url = Api.GLOVALPILLARVALIDATIONFINALLIST + this.pillarSelectedGovHis;
      }
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.showLoader = false;
        if (data.success) {
          if (this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
            this.validationReqHisData = data.data;
            this.selectedValidationReqHisData = this.validationReqHisData[0]["historyList"];
          } else {
            this.selectedValidationReqHisData = data.data;
          }
        }
      });
    },
    viewPractice(id, principleName, pillarName, principleId) {
      for (const element of this.selectedLevelData) {
        if (element.requestId == id) {
          this.levelId = element.levelId;
        }
      }
      this.$router.push("/viewPractice/" + this.levelId + "/" + pillarName + "/" + this.submittedReqStatus.id + "/" + id + "/" + principleName + "/" + principleId + "/" + 0);
    },
    reviewForGlobalValidation(id, principleName, pillarName, practiceId, review, levelId, pillarId) {
      if (review == 1 && this.loggedInRole == this.roles.SYSTEMLEVELADMINNAME) return;
      this.$store.dispatch("reviewRequest", review);
      if (review == 2) {
        let principleData = this.validationReqHisData.find((el) => el.pillarId == pillarId);
        let reqData = principleData["historyList"].find((el) => el.reqId == id);
        this.$store.dispatch("validationReqFeedback", {
          reviewDecision: reqData.reviewDecision,
          feedback: reqData.feedback
        });
      }
      this.$router.push("/review-request/" + levelId + "/" + pillarName + "/." + this.submittedReqStatus.id + "/" + id + "/" + principleName + "/" + practiceId + "/" + review); ///review-request/:levelId/:pillarName/:plantId/:requestId/:principleName/:pillarId/:edit
    }, ///review-request/3/1/45/1.%20Management%20Leadership%20Commitment/4/0
    requestValidation(id, principleName, pillarName, practiceId, review, levelId, pillarId) {
      this.$store.dispatch("reviewRequest", review);
      if (review == 2) {
        let principleData = this.validationReqHisData.find((el) => el.pillarId == pillarId);
        let reqData = principleData["historyList"].find((el) => el.reqId == id);
        this.$store.dispatch("validationReqFeedback", {
          reviewDecision: reqData.reviewDecision,
          feedback: reqData.feedback
        });
      }
      this.$router.push("/requestValidation/" + levelId + "/" + pillarName + "/" + this.submittedReqStatus.id + "/" + id + "/" + principleName + "/" + practiceId + "/" + 0);
    },
    // viewPracticeBtn(id) {
    //   for (let i = 0; i < this.historyprincipalData.length; i++) {
    //     if (this.historyprincipalData[i].requestId == id) {
    //       this.historyData = this.historyprincipalData[i].previousRequestId;
    //       this.levelId = this.historyprincipalData[i].levelId;
    //       this.pillarName = this.historyprincipalData[i].pillarName;
    //       this.principleId = this.historyprincipalData[i].principleId;
    //      this.principleName= this.historyprincipalData[i].principleName;
    //     }
    //   }
    //  this.$router.push(
    //       "/viewPractice/" +
    //         this.levelId +
    //         "/" +
    //         this.pillarName +
    //         "/" +
    //        this.submittedReqStatus.id +
    //         "/" +
    //         id +
    //         "/" +
    //         this.principleName +
    //         "/" +
    //         this.principleId
    //     );
    // },
    editRequest(id, edits = 1) {
      if (!this.continueToAssignPlantValidationStatus) return;

      for (const element of this.historyprincipalData) {
        if (element.requestId == id) {
          this.levelId = element.levelId;
          this.pillarIdD = element.pillarId;
          if (this.pillarNameSelected != null && this.pillarNameSelected != "" && this.pillarNameSelected != undefined) {
            this.pillarName = this.pillarNameSelected;
          } else {
            this.pillarName = element.pillarName;
          }
          this.principleNum = element.principleNumber;
          this.principleName = element.principleName;
          this.principleId = element.principleId;
        }
      }
      let principleNameAndNum = this.principleNum + ". " + this.principleName;

      this.$router.push("/viewRequest/" + this.levelId + "/" + this.pillarName + "/" + principleNameAndNum + "/" + this.principleId + "/" + id + "/" + edits);
    },
    viewConsolidated(id, edit = 0, status = "") {
      if (edit == 1 && (status == "Reviewed" || !this.submittedReqStatus.submitRequestStatus)) {
        return;
      } else {
        for (const element of this.historyprincipalData) {
          if (element.requestId == id) {
            this.levelId = element.levelId;
            this.pillarIdD = element.pillarId;

            if (this.pillarNameSelected != null && this.pillarNameSelected != "" && this.pillarNameSelected != undefined) {
              this.pillarName = this.pillarNameSelected;
            } else {
              this.pillarName = element.pillarName;
            }
            this.principleNum = element.principleNumber;
            this.principleName = element.principleName;
            this.principleId = element.principleId;
            if (element.previousRequestId && element.previousRequestId.length > 0) {
              this.historyData = element.previousRequestId;
              this.previousRequestId = JSON.stringify(element.previousRequestId);
            }
          }
        }

        let principleNameAndNum = this.principleNum + ". " + this.principleName;

        if (this.loggedInRole == this.roles.GLOBALPILLARLEADERNAME) {
          this.$router.push("/viewRequest/" + this.levelId + "/" + this.pillarName + "/" + principleNameAndNum + "/" + this.principleId + "/" + id + "/" + edit);
        } else {
          if (this.historyData.length == 0) {
            this.$router.push(
              "/viewPractice/" +
              this.levelId +
              "/" +
              this.pillarName +
              "/" +
              this.submittedReqStatus.id +
              "/" +
              id +
              "/" +
              principleNameAndNum +
              "/" +
              this.principleId +
              "/" +
              edit
            );
          } else {
            this.$router.push(
              "/viewConsolidatedRequest/" +
              this.levelId +
              "/" +
              this.pillarName +
              "/" +
              this.plantId +
              "/" +
              id +
              "/" +
              principleNameAndNum +
              "/" +
              this.principleId +
              "/" +
              this.previousRequestId
            );
          }
        }
      }
    },

    pillarChanged(pillar, status) {
      if (status == "N") return;
      this.$store.dispatch("pillarSelectedInGov", pillar.pillarId);
      this.$store.dispatch("pillarNameSelectedInGov", pillar.pillarName);
      this.$store.dispatch("levelSelectedGov", "");
      this.selectedLevelData = [];
      if (this.validationReqStatus) this.getPrincipleDataForValidation();
      else this.getPrincipleData(this.submittedReqStatus.objectType);

      this.pillarN = pillar.pillarName;
      this.showLoader = true;
    },
    pillarChangedReq(pillar, status) {
      if (status == "N") return;
      if (this.validationReqStatus && this.loggedInRole == this.roles.PLANTPOLEADERNAME) {
        let data = this.validationReqHisData.find((el) => el.pillarId == pillar.pillarId);
        this.selectedValidationReqHisData = data["historyList"];
        this.$store.dispatch("pillarSelectedGovHis", pillar.pillarId);
        this.pillarId = pillar.pillarId;
        this.pillarNameSelected = pillar.pillarName;
        return;
      }
      this.$store.dispatch("pillarSelectedGovHis", pillar.pillarId);
      this.pillarId = pillar.pillarId;
      this.pillarNameSelected = pillar.pillarName;
      if (this.validationReqStatus && (this.loggedInRole == this.roles.GLOBALPILLARLEADERNAME || this.loggedInRole == this.roles.SYSTEMLEVELADMINNAME)) {
        this.getPillarWiseHisryDataForValidation();
        return;
      }

      this.getPillarWiseHisryData();
      this.showLoader = true;
    },
    principalChanged(id) {
      if (id == this.principalSelectedGov) {
        this.$store.dispatch("principalSelectedGov", 0);
      } else this.$store.dispatch("principalSelectedGov", id);
      let principalData = this.principalData.find((el) => el.principleId == id);
      if (this.validationReqStatus) {
        this.validationReqData = principalData["validationRequest"];
      } else {
        this.$store.dispatch("levelSelectedGov", principalData.validationRequest[0].levelId);
        this.selectedLevelData = principalData["levelDetails"][0]["levelWiseRequestDetails"];
      }
    },
    dateSetting() { },
    levelChanged(id, princpleIndex, levelIndex) {
      this.selectedLevelData = this.principalData[princpleIndex]["levelDetails"][levelIndex]["levelWiseRequestDetails"];

      this.$store.dispatch("levelSelectedGov", id);
    },

    callPerformAction(principle, level) {
      // /:plantId/:pillarId/:principle
      if (!this.submittedReqStatus.performActionButton) return;
      let reqArray = [];

      principle.levelDetails.forEach((element) => {
        if (element.levelId == level) {
          element.levelWiseRequestDetails.forEach((element1) => {
            reqArray.push(element1.requestId);
          });
        }
      });

      this.$router.push(
        "/perform-action/" + this.submittedReqStatus.id + "/" + this.pillarSelectedInGov + "/" + principle.principleId + "/" + level + "/" + JSON.stringify(reqArray)
      );
    },

    practiceDescription(actionDesc) {
      if (actionDesc) return actionDesc.length < 50 ? actionDesc : actionDesc.substr(0, 45) + "...";
      else return "";
    },
    submittedByText(submittedByData) {
      if (submittedByData) {
        if (Array.isArray(submittedByData)) {
          let joinedData = submittedBy.join(", ");
          return joinedData.length < 50 ? joinedData : joinedData.substr(0, 45) + "...";
        } else {
          return submittedByData.length < 50 ? submittedByData : submittedByData.substr(0, 45) + "...";
        }
      } else return "";
    },
    getDateSettingData() {
      ApiCalls.httpCallDetails(Api.GETGOVERNANCEDATESETTING, "get").then((data) => {
        if (data.success) {
          let res = data.data;
          let objUpdatedBy = {};
          let objUpdatedDate = {};
          let objUpdatedTime = {};
          let objGovId = {};
          let getEndDate = {};
          let getStartDate = {};
          let getActive = {};
          let flag = false;
          for (const key in res) {
            if (Object.hasOwnProperty.call(res, key)) {
              const element = res[key];
              flag = element && element.govId ? false : true;
              objGovId[key] = element && element.govId ? element.govId : null;
              objUpdatedBy[key] = element && element.updatedBy ? element.updatedBy : null;

              objUpdatedDate[key] = moment(new Date(element && element.updatedTimeStamp ? element.updatedTimeStamp : null)).format("DD-MMM-YYYY");

              objUpdatedTime[key] = moment(new Date(element && element.updatedTimeStamp ? element.updatedTimeStamp : null)).format("LT");

              getEndDate[key] = element && element.endDate ? element.endDate : null;
              getStartDate[key] = element && element.startDate ? element.startDate : null;
              getActive[key] = element && element.active;
            }
          }

          this.updatedByUserPopup = objUpdatedBy;
          this.updatedByUserDatePopup = objUpdatedDate;
          this.updatedByUserTimePopup = objUpdatedTime;
          this.getStartDate = getStartDate;
          this.getEndDate = getEndDate;
          this.getActive = getActive;
          this.govIds = objGovId;
          this.isNull = flag;
        }
      });
    },

    setLoader(evt) {
      this.showLoader = evt;
      if (!evt) {
        this.getDateSettingData();
        this.getSubmittedReqStatus();
      }
    },
    showingDatax(count) {
      let showData = this.perPage * this.currentPage;
      let showFirstData = showData - this.perPage;
      if (showData > count) {
        showData = count;
      }
      showFirstData = showFirstData + 1;
      return "Showing " + showFirstData + " to " + showData + " of " + count + " records";
    },
    showingData(count) {
      let showData = this.perPage * this.currentPage;
      let showFirstData = showData - this.perPage;
      if (showData > count) {
        showData = count;
      }
      showFirstData = showFirstData + 1;
      return "Showing " + showFirstData + " to " + showData + " of " + count + " records";
    },
    plantsData(data = []) {
      return data.join(", ");
    },
    publishAssesment() {
      this.$refs["publishPopup"].hide();
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.PUBLISHASSESSMENT, "put", {}).then((data) => {
        this.showLoader = false;
        if (data.success) {
          this.getPillarWiseCountForValidation();
          this.getPillarWiseCountHisryForValidation();
        }
      });
    }
  }
};
</script>
<style scoped>
.calender-icon {
  vertical-align: sub !important;
}

.detail-con {
  padding: 2rem;
}

.plant-dd {
  width: 20%;
  align-items: center;
}

.disable_fd {
  background-color: #f9f9f9 !important;
  border: 1px solid #d5d2d2;
  opacity: 1;
}

.dd-con {
  display: flex;
  align-items: center;
}

.cml-by {
  font-family: "BarlowSb", sans-serif;
  font-size: 1.333rem;
  color: #d35757;
  text-align: right;
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
}

.cmp-by-txt {
  margin-left: 0.3rem;
  margin-top: 0.5rem;
}

.cahge-req-btn {
  background: #274ba7;
  border-color: #274ba7;
  padding: 1rem;
  height: 3rem;
  padding-top: 0.5rem;
  margin-left: 2rem;
}

.cahge-req-btn:hover {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #0f3189 0% 0% no-repeat padding-box !important;
}

.cahge-req-btn:active {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.cahge-req-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.main-header {
  font-family: "BarlowM", sans-serif;
  font-size: 1.333rem;
}

.title-header {
  font-family: "BarlowM", sans-serif;
  font-size: 1.666rem;
}

.my-req {
  margin-top: 2rem;
}

.text-cen {
  text-align: center;
}

.text-rt {
  text-align: right;
}

.pagination-con {
  display: flex;
  justify-content: center;
}

.pillar-con {
  display: table;
  table-layout: fixed;
  /* display: flex; */
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  background: #eff3ff;
  margin-top: 1.5rem;
  height: 64px;
}

.pillar-info {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  /* display: flex; */
  width: auto;
  padding: 1rem;
  border-right: 1px solid #dedbdb;
  position: relative;
  cursor: pointer;
}

.pillar-info:hover {
  background: #d9dff3;
}

.first-col {
  border-radius: 6px 0 0 6px !important;
}

.last-col {
  border-radius: 0 6px 6px 0 !important;
}

.active-pillar {
  background: #00428f;
  color: #fff;
}

.active-pillar:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 40%;
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.active-pillar:hover:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 40%;
  width: 0;
  height: 0;
  border-top: solid 10px #d9dff3;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.pillar-code {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
}

.pillar-count {
  font-size: 1rem;
  font-family: "BarlowB", sans-serif;
  padding: 0.25rem;
  background-color: #d55050 !important;
  color: #fff;
  border-radius: 50%;
  margin-left: 0.4rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.pillar-name-con {
  margin-top: 1rem;
}

.pracities-no {
  font-size: 1.333rem;
  font-family: "BarlowB", sans-serif;
}

.practice-con {
  background: #eff3ff;
  padding: 1rem;
  margin-top: 1rem;
  padding-bottom: 0rem;
  height: 8rem;
  cursor: pointer;
}

.practice-con-inactv {
  height: 4rem !important;
}

.flex-con {
  display: flex;
  align-items: center;
}

.prac-number {
  background: #687b9b;
  color: #fff;
  border-radius: 50%;
  padding: 1rem;
  min-width: 2rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

.level-con {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.sing-level {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  /* display: flex; */
  width: auto;
  padding: 1rem;
}

.level-name {
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

.level-hr {
  margin-bottom: 0rem;
}

.setting-text {
  margin-left: 1rem;
  font: 1.16rem "BarlowM", sans-serif;
}

.date-setting-button-div {
  text-align: right;
  margin-top: -40px;
}

.active-level {
  font-family: "BarlowSb", sans-serif;
  color: #274ba7;
  font-size: 1.166rem;
}

.active-hr {
  width: 1.5rem;
  border-bottom: 3px solid #274ba7;
  border-radius: 10px;
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.practice-main-con {
  position: relative;
}

.perform-act-con {
  font-family: "BarlowM", sans-serif;
  position: absolute;
  right: 1rem;
  z-index: 10;
  top: 9.5rem;
}

.perform-btn {
  font-size: 1rem;
}

.lable-dp:first-letter {
  text-transform: capitalize;
}

.dd-selection {
  width: 40%;
}

.inactive-pillar {
  cursor: not-allowed;
  background: #fcfcfc;
  color: #bfbfbf;
}

.edit-mr {
  margin-left: 1rem;
}

.edit-btn {
  margin-left: 1rem;
}

.gp-btn {
  text-align: right;
}

.opacity-01 {
  opacity: 0.2;
}

.validation-btn {
  width: 7rem !important;
}

/* --------------- pop css ---------------- */
.pop-up-title {
  color: #274ba7;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.publish-btn {
  border-color: rgb(39, 75, 167);
  background-color: rgb(39, 75, 167);
  color: rgb(255, 255, 255);
  margin-top: 0.5rem;
  padding: 0.375rem 1rem;
}

.discard-btn {
  color: #274ba7;
  border-color: transparent;
  background-color: transparent;
  margin-top: 0.5em;
  width: 8.333333333333334rem;
  height: 2.9166666666666665rem;
  padding: 0.375rem 1rem;
  margin-right: 0.5rem;
}

.discard-btn:active {
  color: #274ba7bd !important;
  border-color: transparent !important;
  background-color: transparent !important;
  margin-top: 0.5em !important;
  margin-right: 0.5rem !important;
}

.bottom-1rem {
  margin-bottom: 1rem;
}

.publish-practice {
  padding-left: 0.5rem;
  /* border-left: 1px solid #e6e6e6; */
}

.plant-validation {
  padding-right: 0.25rem;
}

.target-completion-current-date {
  font-size: 1rem;
  font-family: "BarlowSb", sans-serif;
  color: #274ba7;
  margin: 0;
}

.popup-text {
  font-size: 1.167rem;
  font-family: "BarlowR", sans-serif;
  color: #313131;
  margin: 0;
}

.target-setting-created--display {
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
  color: #313131;
  margin: 0;
}

.input-target-completion-date {
  border-color: #313131;
  padding: 4px;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.outer-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-label {
  font: 1.166rem "BarlowM", sans-serif;
  color: #313131;
}

.bottem-label {
  font: 0.9rem "BarlowR", sans-serif;
  color: #313131;
}

.input-group {
  margin-top: 1rem;
}

.errorMsg {
  font: 1.166rem "BarlowM", sans-serif;
  color: red;
  text-align: center;
  width: 100%;
  padding: 1rem 0 0 0;
}

.mb-3 {
  margin-bottom: 1rem !important;
  width: 25%;
  padding: 0 1% 0 0;
}

/* --------------- pop css ---------------- */
.no-fl-con {
  text-align: center;
  padding-top: 8rem;
  padding-bottom: 11rem;
}

.no-fl-hd {
  font-size: 1.166rem !important;
  font-family: "BarlowB", sans-serif !important;
  margin-top: 1rem;
}

.no-fl-sub {
  font-size: 1.166rem !important;
  font-family: "BarlowR", sans-serif !important;
}

.no-fl-img {
  width: 7rem;
}
</style>
