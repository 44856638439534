<template>
  <b-modal id="governanceCompletionDate" ref="governanceCompletionDate">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <p class="pop-up-title">{{ text }}</p>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>

    <template #default="{}">
      <div>
        <div class="pop-up-title bottom-1rem">
          Set Timeline for Governance Process
        </div>
        <div class="outer-block">
          <div class="mb-3">
            <span class="top-label"> Plant Associate Feedback </span>
            <b-input-group>
              <b-form-input id="plant-associate-feedback-start-date-input" v-model="plantAssociateFeedbackStartDateInput"
                type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="plant-associate-feedback-start-date-setting"
                  v-model="plantAssociateFeedbackStartDate" button-only right
                  :disabled="getActive.plantAssociateFB === 'N'" locale="en-US"
                  aria-controls="plant-associate-feedback-start-date-input" dark :min="minDate[0]"
                  label-no-date-selected="Start Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 1)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input id="plant-associate-feedback-end-date-input" v-model="plantAssociateFeedbackEndDateInput"
                type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="plant-associate-feedback-end-date-setting" v-model="plantAssociateFeedbackEndDate"
                  button-only right :disabled="getActive.plantAssociateFB === 'N'" locale="en-US"
                  aria-controls="plant-associate-feedback-end-date-input" dark :min="minDate[1]"
                  label-no-date-selected="End Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 2)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <span class="bottem-label" v-if="updatedByUser.plantAssociateFB">

              Updated by {{ updatedByUser.plantAssociateFB }}
              {{ updatedByUserDate.plantAssociateFB }},
              {{ updatedByUserTime.plantAssociateFB }} EST
            </span>
          </div>
          <div class="mb-3">
            <span class="top-label"> Plant Pillar/POL Feedback </span>
            <b-input-group>
              <b-form-input id="plant-pillar-feedback-start-date-input" v-model="plantPillarFeedbackStartDateInput"
                type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="plant-pillar-feedback-start-date-setting" v-model="plantPillarFeedbackStartDate"
                  button-only right :disabled="getActive.plantPOFB === 'N'" locale="en-US"
                  aria-controls="plant-pillar-feedback-start-date-input" dark :min="minDate[2]"
                  label-no-date-selected="Start Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 3)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input id="plant-pillar-feedback-end-date-input" v-model="plantPillarFeedbackEndDateInput"
                type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="plant-pillar-feedback-end-date-setting" v-model="plantPillarFeedbackEndDate"
                  button-only right :disabled="getActive.plantPOFB === 'N'" locale="en-US"
                  aria-controls="plant-pillar-feedback-end-date-input" dark :min="minDate[3]"
                  label-no-date-selected="End Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 4)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <span class="bottem-label" v-if="updatedByUser.plantPOFB">

              Updated by {{ updatedByUser.plantPOFB }}
              {{ updatedByUserDate.plantPOFB }},
              {{ updatedByUserTime.plantPOFB }} EST
            </span>
          </div>
          <div class="mb-3">
            <span class="top-label"> Regional Pillar/POL Feedback </span>
            <b-input-group>
              <b-form-input id="regional-pillar-feedback-start-date-input" v-model="regionalPillarFeedbackStartDateInput"
                type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="regional-pillar-feedback-start-date-setting"
                  v-model="regionalPillarFeedbackStartDate" button-only right :disabled="getActive.regionPOFB === 'N'"
                  locale="en-US" aria-controls="regional-pillar-feedback-start-date-input" dark :min="minDate[4]"
                  label-no-date-selected="Start Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 5)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input id="regional-pillar-feedback-end-date-input" v-model="regionalPillarFeedbackEndDateInput"
                type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="regional-pillar-feedback-end-date-setting" v-model="regionalPillarFeedbackEndDate"
                  button-only :disabled="getActive.regionPOFB === 'N'" right locale="en-US"
                  aria-controls="regional-pillar-feedback-end-date-input" dark :min="minDate[5]"
                  label-no-date-selected="End Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 6)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <span class="bottem-label" v-if="updatedByUser.regionPOFB">

              Updated by {{ updatedByUser.regionPOFB }}
              {{ updatedByUserDate.regionPOFB }},
              {{ updatedByUserTime.regionPOFB }} EST
            </span>
          </div>
          <div class="mb-3">
            <span class="top-label"> Global Pillar Feedback </span>
            <b-input-group>
              <b-form-input id="global-pillar-feedback-start-date-input" v-model="globalPillarFeedbackStartDateInput"
                type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="global-pillar-feedback-start-date-setting" v-model="globalPillarFeedbackStartDate"
                  button-only right :disabled="getActive.globalPOFB === 'N'" locale="en-US"
                  aria-controls="global-pillar-feedback-start-date-input" dark :min="minDate[6]"
                  label-no-date-selected="Start Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 7)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input id="global-pillar-feedback-end-date-input" v-model="globalPillarFeedbackEndDateInput"
                type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="global-pillar-feedback-end-date-setting" v-model="globalPillarFeedbackEndDate"
                  button-only :disabled="getActive.globalPOFB === 'N'" right locale="en-US"
                  aria-controls="global-pillar-feedback-end-date-input" dark :min="minDate[7]"
                  label-no-date-selected="End Date" :date-format-options="{
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    }" @context="onContext($event, 8)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
            <span class="bottem-label" v-if="updatedByUser.globalPOFB">

              Updated by {{ updatedByUser.globalPOFB }}
              {{ updatedByUserDate.globalPOFB }},
              {{ updatedByUserTime.globalPOFB }} EST
            </span>
          </div>
        </div>
      </div>
      <div style="display: flex; padding: 0" class="col-12">
        <div class="plant-validation col-3 mb-3">
          <div class="pop-up-title bottom-1rem">
            Set Timeline for Plant Validation
          </div>
          <div class="outer-block">
            <div style="width: 100%">
              <span class="top-label"> Plant POL Feedback </span>
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input"
                  v-model="plantValidationFeedbackStartDateInput" type="text" placeholder="Start Date" autocomplete="off"
                  class="input-target-completion-date" disabled></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting"
                    v-model="plantValidationFeedbackStartDate" button-only right
                    :disabled="getActive.plantPoValidation === 'N'" locale="en-US"
                    aria-controls="plant-associate-feedback-start-date-input" dark :min="minDate[8]"
                    label-no-date-selected="Start Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }" @context="onContext($event, 9)">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <b-form-input id="plant-associate-feedback-end-date-input" v-model="plantValidationFeedbackEndDateInput"
                  type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date"
                  disabled></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-end-date-setting"
                    v-model="plantValidationFeedbackEndDate" button-only right
                    :disabled="getActive.plantPoValidation === 'N'" locale="en-US"
                    aria-controls="plant-associate-feedback-end-date-input" dark :min="minDate[9]"
                    label-no-date-selected="End Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }" @context="onContext($event, 10)">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <span class="bottem-label" v-if="updatedByUser.plantPoValidation">

                Updated by {{ updatedByUser.plantPoValidation }}
                {{ updatedByUserDate.plantPoValidation }},
                {{ updatedByUserTime.plantPoValidation }} EST
              </span>
            </div>
          </div>
        </div>
        <div class="publish-practice col-3 mb-3">
          <div class="pop-up-title bottom-1rem">
            Set Timeline to Review Validation
          </div>
          <div class="outer-block">
            <div style="width: 100%">
              <span class="top-label"> Global Pillar Validation Review </span>
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input" v-model="globalValidationStartDateInput"
                  type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date"
                  disabled></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting" v-model="globalValidationStartDate"
                    button-only right :disabled="getActive.globalAssesmentValidationTimeline === 'N'
                      " locale="en-US" aria-controls="plant-associate-feedback-start-date-input" dark
                    :min="minDate[10]" label-no-date-selected="Start Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }" @context="onContext($event, 11)">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <b-form-input id="plant-associate-feedback-end-date-input" v-model="globalValidationEndDateInput"
                  type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date"
                  disabled></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-end-date-setting" v-model="globalValidationEndDate"
                    button-only right :disabled="getActive.globalAssesmentValidationTimeline === 'N'
                      " locale="en-US" aria-controls="plant-associate-feedback-end-date-input" dark :min="minDate[11]"
                    label-no-date-selected="End Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }" @context="onContext($event, 12)">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <span class="bottem-label" v-if="updatedByUser.globalAssesmentValidationTimeline">

                Updated by
                {{ updatedByUser.globalAssesmentValidationTimeline }}
                {{ updatedByUserDate.globalAssesmentValidationTimeline }},
                {{ updatedByUserTime.globalAssesmentValidationTimeline }} EST
              </span>
            </div>
          </div>
        </div>
        <div class="publish-practice col-3 mb-3">
          <div class="pop-up-title bottom-1rem" style="height: 2rem"></div>
          <div class="outer-block">
            <div style="width: 100%">
              <span class="top-label"> Publish New Assessment </span>
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input" v-model="publishFeedbackStartDateInput"
                  type="text" placeholder="Select Date" autocomplete="off" class="input-target-completion-date"
                  disabled></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting" v-model="publishFeedbackStartDate"
                    button-only right :disabled="getActive.publish === 'N'" locale="en-US"
                    aria-controls="plant-associate-feedback-start-date-input" dark :min="minDate[12]"
                    label-no-date-selected="Select Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }" @context="onContext($event, 13)">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <span class="bottem-label" v-if="updatedByUser.publish">

                Updated by {{ updatedByUser.publish }}
                {{ updatedByUserDate.publish }},
                {{ updatedByUserTime.publish }} EST
              </span>
            </div>
            <input type="hidden" :value="selecteduserA" />
          </div>
        </div>
      </div>
      <div class="errorMsg">
        {{ errorMsg() }}
      </div>
    </template>

    <template #modal-footer="{ close }">
      <div class="rw-act-con">
        <b-button @click="close()" pill variant="outline-secondary" class="
            page-heading-tab
            btn
            start-course-bt
            ed-st-btn
            btn-secondary
            rounded-pill
          " style="
            color: #274ba7;
            border-color: transparent;
            background-color: transparent;
            margin-top: 0.5em;
            width: 8.333333333333334rem;
            height: 2.9166666666666665rem;
            margin-right: 0.5rem;
          ">
          Discard
        </b-button>
        <b-button @click="setDates()" pill variant="outline-secondary" class="
            page-heading-tab
            btn
            start-course-bt
            ed-st-btn
            btn-secondary
            rounded-pill
          " style="
            border-color: #274ba7;
            background-color: #274ba7;
            color: #fff;
            margin-top: 0.5em;
            width: 8.333333333333334rem;
            height: 2.9166666666666665rem;
          ">
          Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import moment from "moment";
export default {
  name: "DateSettingPopup",
  props: {
    text: String,
    getStartDate: Object,
    getEndDate: Object,
    getActive: Object,
    updatedByUser: Object,
    updatedByUserDate: Object,
    updatedByUserTime: Object,
    govIds: Object,
    isNull: Boolean,
  },
  data() {
    return {
      // governanceCompletionDate: null,
      // targetCompletionDateInput: null,
      minDate: [new Date()],
      globalPillarFeedbackStartDateInput: null,
      globalPillarFeedbackEndDateInput: null,
      regionalPillarFeedbackStartDateInput: null,
      regionalPillarFeedbackEndDateInput: null,
      plantPillarFeedbackStartDateInput: null,
      plantPillarFeedbackEndDateInput: null,
      plantAssociateFeedbackStartDateInput: null,
      plantAssociateFeedbackEndDateInput: null,
      globalPillarFeedbackStartDate: null,
      globalPillarFeedbackEndDate: null,
      regionalPillarFeedbackStartDate: null,
      regionalPillarFeedbackEndDate: null,
      plantPillarFeedbackStartDate: null,
      plantPillarFeedbackEndDate: null,
      plantAssociateFeedbackStartDate: null,
      plantAssociateFeedbackEndDate: null,
      plantValidationFeedbackStartDateInput: null,
      plantValidationFeedbackEndDateInput: null,
      plantValidationFeedbackStartDate: null,
      plantValidationFeedbackEndDate: null,
      globalValidationStartDate: null,
      globalValidationEndDate: null,
      globalValidationStartDateInput: null,
      globalValidationEndDateInput: null,
      publishFeedbackStartDate: null,
      publishFeedbackEndDate: null,
      publishFeedbackStartDateInput: null,
      publishFeedbackEndDateInput: null,
      focused: false,
    };
  },
  computed: {
    selecteduserA() {
      let flag =
        this.globalPillarFeedbackStartDateInput === "Start Date" ||
          this.globalPillarFeedbackEndDateInput === "End Date" ||
          this.regionalPillarFeedbackStartDateInput === "Start Date" ||
          this.regionalPillarFeedbackEndDateInput === "End Date" ||
          this.plantPillarFeedbackStartDateInput === "Start Date" ||
          this.plantPillarFeedbackEndDateInput === "End Date" ||
          this.plantAssociateFeedbackStartDateInput === "Start Date" ||
          this.plantAssociateFeedbackEndDateInput === "End Date" ||
          this.plantValidationFeedbackStartDateInput === "Start Date" ||
          this.plantValidationFeedbackEndDateInput === "End Date" ||
          this.publishFeedbackStartDateInput === "Select Date" ||
          // this.publishFeedbackEndDateInput === "End Date"
          this.globalValidationStartDateInput === "Start Date" ||
          this.globalValidationEndDateInput === "End Date"
          ? true
          : false;
      // // console.log("flag",flag,this.focused)
      // // console.log(
      //   this.globalPillarFeedbackStartDateInput +" "+
      // this.globalPillarFeedbackEndDateInput +" "+
      // this.regionalPillarFeedbackStartDateInput +" "+
      // this.regionalPillarFeedbackEndDateInput +" "+
      // this.plantPillarFeedbackStartDateInput +" "+
      // this.plantPillarFeedbackEndDateInput +" "+
      // this.plantAssociateFeedbackStartDateInput +" "+
      // this.plantAssociateFeedbackEndDateInput
      // )
      // // console.log(this.focused + " 1");
      if (flag && this.focused) {
        this.globalPillarFeedbackStartDate = this.getStartDate.globalPOFB
          ? this.getStartDate.globalPOFB
          : "Start Date";
        this.globalPillarFeedbackEndDate = this.getEndDate.globalPOFB
          ? this.getEndDate.globalPOFB
          : "End Date";
        this.regionalPillarFeedbackStartDate = this.getStartDate.regionPOFB
          ? this.getStartDate.regionPOFB
          : "Start Date";
        this.regionalPillarFeedbackEndDate = this.getEndDate.regionPOFB
          ? this.getEndDate.regionPOFB
          : "End Date";
        this.plantPillarFeedbackStartDate = this.getStartDate.plantPOFB
          ? this.getStartDate.plantPOFB
          : "Start Date";
        this.plantPillarFeedbackEndDate = this.getEndDate.plantPOFB
          ? this.getEndDate.plantPOFB
          : "End Date";
        this.plantAssociateFeedbackStartDate = this.getStartDate
          .plantAssociateFB
          ? this.getStartDate.plantAssociateFB
          : "Start Date";
        this.plantAssociateFeedbackEndDate = this.getEndDate.plantAssociateFB
          ? this.getEndDate.plantAssociateFB
          : "End Date";
        this.plantValidationFeedbackStartDate = this.getStartDate
          .plantPoValidation
          ? this.getStartDate.plantPoValidation
          : "Start Date";
        this.plantValidationFeedbackEndDate = this.getEndDate.plantPoValidation
          ? this.getEndDate.plantPoValidation
          : "End Date";
        this.globalValidationStartDate = this.getStartDate
          .globalAssesmentValidationTimeline
          ? this.getStartDate.globalAssesmentValidationTimeline
          : "Start Date";
        this.globalValidationEndDate = this.getEndDate
          .globalAssesmentValidationTimeline
          ? this.getEndDate.globalAssesmentValidationTimeline
          : "End Date";
        this.publishFeedbackStartDate = this.getStartDate.publish
          ? this.getStartDate.publish
          : "Select Date";
      }
      this.minDate[0] = new Date();
      return 1;
    },
  },
  methods: {
    errorMsg() {
      let flag = this.isValid();

      if (flag) return "";
      else return "Dates are invalid";
    },
    focusIn() {
      this.focused = false;
    },
    focusOut() {
      this.focused = true;
    },
    onContext(ctx, pos) {
      if (!ctx.selectedDate && this.isNull) {
        return;
      } else {
        let selectedDate, date;
        switch (pos) {
          case 1:
            this.plantAssociateFeedbackStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.plantAssociateFeedbackStartDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[1] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            break;
          case 2:
            this.plantAssociateFeedbackEndDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.plantAssociateFeedbackEndDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[2] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            selectedDate = new Date(this.plantAssociateFeedbackEndDateInput);

            date = new Date(selectedDate.setDate(selectedDate.getDate() + 1));
            this.plantPillarFeedbackStartDate = date;
            break;
          case 3:
            this.plantPillarFeedbackStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.plantPillarFeedbackStartDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[3] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            break;
          case 4:
            this.plantPillarFeedbackEndDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.plantPillarFeedbackEndDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[4] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );

            selectedDate = new Date(this.plantPillarFeedbackEndDateInput);
            date = new Date(selectedDate.setDate(selectedDate.getDate() + 1));

            this.regionalPillarFeedbackStartDate = date;

            break;
          case 5:
            this.regionalPillarFeedbackStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.regionalPillarFeedbackStartDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[5] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            break;
          case 6:
            this.regionalPillarFeedbackEndDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.regionalPillarFeedbackEndDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[6] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            selectedDate = new Date(this.regionalPillarFeedbackEndDateInput);
            date = new Date(selectedDate.setDate(selectedDate.getDate() + 1));

            this.globalPillarFeedbackStartDate = date;

            break;
          case 7:
            this.globalPillarFeedbackStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.globalPillarFeedbackStartDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[7] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            break;
          case 8:
            this.globalPillarFeedbackEndDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.globalPillarFeedbackEndDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[8] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );

            selectedDate = new Date(this.globalPillarFeedbackEndDateInput);
            date = new Date(selectedDate.setDate(selectedDate.getDate() + 1));

            this.plantValidationFeedbackStartDate = date;
            break;

          case 9:
            this.plantValidationFeedbackStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.plantValidationFeedbackStartDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[9] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            break;
          case 10:
            this.plantValidationFeedbackEndDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.plantValidationFeedbackEndDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[10] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            selectedDate = new Date(this.plantValidationFeedbackEndDate);
            date = new Date(selectedDate.setDate(selectedDate.getDate() + 1));
            this.globalValidationStartDate = date;
            break;
          case 11:
            this.globalValidationStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.globalValidationStartDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[11] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            break;
          case 12:
            this.globalValidationEndDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.globalValidationEndDateInput);
            date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            this.minDate[12] = new Date(
              this.compareDate(date, new Date()) ? date : new Date()
            );
            selectedDate = new Date(this.globalValidationEndDate);
            date = new Date(selectedDate.setDate(selectedDate.getDate() + 1));
            this.publishFeedbackStartDate = date;
            break;
          case 13:
            this.publishFeedbackStartDateInput = ctx.selectedFormatted;
            selectedDate = new Date(this.publishFeedbackStartDateInput);
            this.date = new Date(
              selectedDate.getFullYear() +
              "/" +
              (selectedDate.getMonth() + 1) +
              "/" +
              (selectedDate.getDate() + 1)
            );
            break;
          default:
            break;
        }
        if (pos === 13 && !this.focused) {
          console.log("*");
          this.focusOut();
          this.selecteduserA();
        }
      }
    },
    compareDate(start, end) {
      start = new Date(start);
      end = new Date(end);
      let flag = moment(start).isAfter(end);
      return flag;
    },
    isValid() {
      let selectedDate = new Date();
      let date = new Date(selectedDate.setDate(selectedDate.getDate() - 1));
      let flag2 =
        this.compareDate(this.plantAssociateFeedbackStartDateInput, date) &&
        this.compareDate(this.plantAssociateFeedbackEndDateInput, date) &&
        this.compareDate(this.plantPillarFeedbackStartDateInput, date) &&
        this.compareDate(this.plantPillarFeedbackEndDateInput, date) &&
        this.compareDate(this.regionalPillarFeedbackStartDateInput, date) &&
        this.compareDate(this.regionalPillarFeedbackEndDateInput, date) &&
        this.compareDate(this.globalPillarFeedbackStartDateInput, date) &&
        this.compareDate(this.globalPillarFeedbackEndDateInput, date) &&
        this.compareDate(this.plantValidationFeedbackStartDateInput, date) &&
        this.compareDate(this.plantValidationFeedbackEndDateInput, date) &&
        this.compareDate(this.globalValidationStartDateInput, date) &&
        this.compareDate(this.globalValidationEndDateInput, date) &&
        this.compareDate(this.publishFeedbackStartDateInput, date);

      if (!flag2) return false;

      let flag =
        this.compareDate(
          this.plantAssociateFeedbackEndDateInput,
          this.plantAssociateFeedbackStartDateInput
        ) &&
        this.compareDate(
          this.plantPillarFeedbackStartDateInput,
          this.plantAssociateFeedbackEndDateInput
        ) &&
        this.compareDate(
          this.plantPillarFeedbackEndDateInput,
          this.plantPillarFeedbackStartDateInput
        ) &&
        this.compareDate(
          this.regionalPillarFeedbackStartDateInput,
          this.plantPillarFeedbackEndDateInput
        ) &&
        this.compareDate(
          this.regionalPillarFeedbackEndDateInput,
          this.regionalPillarFeedbackStartDateInput
        ) &&
        this.compareDate(
          this.globalPillarFeedbackStartDateInput,
          this.regionalPillarFeedbackEndDateInput
        ) &&
        this.compareDate(
          this.globalPillarFeedbackEndDateInput,
          this.globalPillarFeedbackStartDateInput
        ) &&
        this.compareDate(
          this.plantValidationFeedbackStartDateInput,
          this.globalPillarFeedbackEndDateInput
        ) &&
        this.compareDate(
          this.plantValidationFeedbackEndDateInput,
          this.plantValidationFeedbackStartDateInput
        ) &&
        this.compareDate(
          this.globalValidationStartDateInput,
          this.plantValidationFeedbackEndDateInput
        ) &&
        this.compareDate(
          this.globalValidationEndDateInput,
          this.globalValidationStartDateInput
        ) &&
        this.compareDate(
          this.publishFeedbackStartDateInput,
          this.globalValidationEndDateInput
        );
      return flag;
    },
    setDates() {
      //// console.log(this.governanceCompletionDate);
      let flag =
        (this.globalValidationEndDateInput &&
          this.globalValidationStartDateInput) ||
          (this.plantValidationFeedbackStartDateInput &&
            this.plantValidationFeedbackEndDateInput) ||
          (this.globalPillarFeedbackStartDateInput &&
            this.globalPillarFeedbackEndDateInput) ||
          (this.regionalPillarFeedbackStartDateInput &&
            this.regionalPillarFeedbackEndDateInput) ||
          (this.plantPillarFeedbackStartDateInput &&
            this.plantPillarFeedbackEndDateInput) ||
          (this.plantAssociateFeedbackStartDateInput &&
            this.plantAssociateFeedbackEndDateInput)
          ? true
          : false;
      this.errorMsg();
      // // console.log(this.globalPillarFeedbackStartDateInput  , this.globalPillarFeedbackEndDateInput )
      let flag2 = this.isValid();
      console.log(this.globalValidationStartDateInput);

      let sd = new Date(this.plantAssociateFeedbackStartDate);
      let ed = new Date(this.plantAssociateFeedbackEndDate);
      if (flag && flag2) {
        this.setLoader(true);
        let pA = {
          govId: this.govIds.plantAssociateFB
            ? this.govIds.plantAssociateFB
            : null,
          active: this.getActive.plantAssociateFB,
          startDate:
            sd.getFullYear() +
            "-" +
            (Math.floor((sd.getMonth() + 1) / 10) == 0
              ? "0" + (sd.getMonth() + 1)
              : sd.getMonth() + 1) +
            "-" +
            (Math.floor(sd.getDate() / 10) == 0
              ? "0" + sd.getDate()
              : sd.getDate()), //2022-12-27
          endDate:
            ed.getFullYear() +
            "-" +
            (Math.floor((ed.getMonth() + 1) / 10) == 0
              ? "0" + (ed.getMonth() + 1)
              : ed.getMonth() + 1) +
            "-" +
            (Math.floor(ed.getDate() / 10) == 0
              ? "0" + ed.getDate()
              : ed.getDate()),
        };
        sd = new Date(this.plantPillarFeedbackStartDate);
        ed = new Date(this.plantPillarFeedbackEndDate);
        let pPO = {
          govId: this.govIds.plantPOFB ? this.govIds.plantPOFB : null,
          active: this.getActive.plantPOFB,
          startDate:
            sd.getFullYear() +
            "-" +
            (Math.floor((sd.getMonth() + 1) / 10) == 0
              ? "0" + (sd.getMonth() + 1)
              : sd.getMonth() + 1) +
            "-" +
            (Math.floor(sd.getDate() / 10) == 0
              ? "0" + sd.getDate()
              : sd.getDate()),
          endDate:
            ed.getFullYear() +
            "-" +
            (Math.floor((ed.getMonth() + 1) / 10) == 0
              ? "0" + (ed.getMonth() + 1)
              : ed.getMonth() + 1) +
            "-" +
            (Math.floor(ed.getDate() / 10) == 0
              ? "0" + ed.getDate()
              : ed.getDate()),
        };

        sd = new Date(this.regionalPillarFeedbackStartDate);
        ed = new Date(this.regionalPillarFeedbackEndDate);

        let rPO = {
          govId: this.govIds.regionPOFB ? this.govIds.regionPOFB : null,
          active: this.getActive.regionPOFB,
          startDate:
            sd.getFullYear() +
            "-" +
            (Math.floor((sd.getMonth() + 1) / 10) == 0
              ? "0" + (sd.getMonth() + 1)
              : sd.getMonth() + 1) +
            "-" +
            (Math.floor(sd.getDate() / 10) == 0
              ? "0" + sd.getDate()
              : sd.getDate()),
          endDate:
            ed.getFullYear() +
            "-" +
            (Math.floor((ed.getMonth() + 1) / 10) == 0
              ? "0" + (ed.getMonth() + 1)
              : ed.getMonth() + 1) +
            "-" +
            (Math.floor(ed.getDate() / 10) == 0
              ? "0" + ed.getDate()
              : ed.getDate()),
        };

        sd = new Date(this.globalPillarFeedbackStartDate);
        ed = new Date(this.globalPillarFeedbackEndDate);

        let gPO = {
          govId: this.govIds.globalPOFB ? this.govIds.globalPOFB : null,
          active: this.getActive.globalPOFB,
          startDate:
            sd.getFullYear() +
            "-" +
            (Math.floor((sd.getMonth() + 1) / 10) == 0
              ? "0" + (sd.getMonth() + 1)
              : sd.getMonth() + 1) +
            "-" +
            (Math.floor(sd.getDate() / 10) == 0
              ? "0" + sd.getDate()
              : sd.getDate()),
          endDate:
            ed.getFullYear() +
            "-" +
            (Math.floor((ed.getMonth() + 1) / 10) == 0
              ? "0" + (ed.getMonth() + 1)
              : ed.getMonth() + 1) +
            "-" +
            (Math.floor(ed.getDate() / 10) == 0
              ? "0" + ed.getDate()
              : ed.getDate()),
        };
        let pVal = {
          govId: this.govIds.plantPoValidation
            ? this.govIds.plantPoValidation
            : null,
          active: this.getActive.plantPoValidation,
          startDate: new Date(this.plantValidationFeedbackStartDate),
          endDate: new Date(this.plantValidationFeedbackEndDate),
        };
        let gVal = {
          govId: this.govIds.globalAssesmentValidationTimeline
            ? this.govIds.globalAssesmentValidationTimeline
            : null,
          active: this.getActive.globalAssesmentValidationTimeline,
          startDate: new Date(this.globalValidationStartDateInput),
          endDate: new Date(this.globalValidationEndDateInput),
        };
        let publish = {
          govId: this.govIds.publish ? this.govIds.publish : null,
          active: this.getActive.publish,
          startDate: new Date(this.publishFeedbackStartDate),
          endDate: new Date(this.publishFeedbackStartDate),
        };
        //        "globalPillarValidation": {
        //   "govId": 0,
        //   "active": "string",
        //   "startDate": "2021-11-18T08:34:02.365Z",
        //   "endDate": "2021-11-18T08:34:02.365Z"  },
        // "publishDate": {
        //   "govId": 0,
        //   "active": "string",
        //   "startDate": "2021-11-18T08:34:02.365Z",
        //   "endDate": "2021-11-18T08:34:02.365Z"  }
        let req = {
          plantAssociate:
            this.plantAssociateFeedbackStartDate &&
              this.plantAssociateFeedbackEndDate
              ? pA
              : null,
          plantPO:
            this.plantPillarFeedbackStartDate && this.plantPillarFeedbackEndDate
              ? pPO
              : null,
          regional:
            this.regionalPillarFeedbackStartDate &&
              this.regionalPillarFeedbackEndDate
              ? rPO
              : null,
          global:
            this.globalPillarFeedbackStartDate &&
              this.globalPillarFeedbackEndDate
              ? gPO
              : null,
          plantPOValidation:
            this.plantValidationFeedbackStartDate &&
              this.plantValidationFeedbackEndDate
              ? pVal
              : null,
          globalPillarValidation:
            this.globalValidationStartDate && this.globalValidationEndDate
              ? gVal
              : null,
          publishDate: this.publishFeedbackStartDate ? publish : null,
        };
        // // console.log(flag, req);
        ApiCalls.httpCallDetails(
          Api.ADDGOVERNANCEDATESETTING,
          "post",
          req
        ).then((data) => {
          // // console.log(data);
          if (data.success) {
            setTimeout(() => {
              this.setLoader(false);
              this.$refs.governanceCompletionDate.hide();
            }, 2000);
          }
        });
      } else {
        // // console.log(flag, this.isValid());
        this.isValid();
      }
    },

    setLoader(evt) {
      this.$emit("setLoader", evt);
    },
  },
};
</script>
<style scoped>
.pop-up-title {
  color: #274ba7;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.bottom-1rem {
  margin-bottom: 1rem;
}

.publish-practice {
  padding-left: 0.5rem;
  /* border-left: 1px solid #e6e6e6; */
}

.plant-validation {
  padding-right: 0.25rem;
}

.target-completion-current-date {
  font-size: 1rem;
  font-family: "BarlowSb", sans-serif;
  color: #274ba7;
  margin: 0;
}

.target-setting-current-date-display {
  font-size: 1.3333333333333333rem;
  font-family: "BarlowR", sans-serif;
  color: #274ba7;
  margin: 0;
}

.target-setting-created--display {
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
  color: #313131;
  margin: 0;
}

.input-target-completion-date {
  border-color: #313131;
  padding: 4px;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.outer-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-label {
  font: 1.166rem "BarlowM", sans-serif;
  color: #313131;
}

.bottem-label {
  font: 0.9rem "BarlowR", sans-serif;
  color: #313131;
}

.input-group {
  margin-top: 1rem;
}

.errorMsg {
  font: 1.166rem "BarlowM", sans-serif;
  color: red;
  text-align: center;
  width: 100%;
  padding: 1rem 0 0 0;
}

.mb-3 {
  margin-bottom: 1rem !important;
  width: 25%;
  padding: 0 1% 0 0;
}

@media only screen and (max-width: 1024px) {}
</style>